<template>
  <div class="shop">
    <div class="topimg">
      <img src="@/assets/img/shop_img.png" alt="">
    </div>
    <div class="contant">
      <div class="tabbar" ref="barscroll">
        <div class="warp" :style="'width:' + 228 / 2 * cate_list.length + 'px'">
          <div class="item" v-for="(item, index) in cate_list" :key="index" @click="tabclick(item, index)">
            <div class="bgimg" v-if="index == 0">
              <img v-if="tabindex == index" src="@/assets/img/shop_tab1_bg_on.png" alt="">
              <img v-else src="@/assets/img/shop_tab1_bg.png" alt="">
            </div>
            <div class="bgimg" v-else>
              <img v-if="tabindex == index" src="@/assets/img/shop_tab2_bg_on.png" alt="">
              <img v-else src="@/assets/img/shop_tab2_bg.png" alt="">
            </div>
            <div class="text" :class="tabindex == index ? 'on' : ''">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <div class="shoplist">
        <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" :immediate-check="false"
          @load="onLoad">
          <div class="listload">
            <div class="item" v-for="(item, index) in goods_list" :Key="index" @click="todetail(item)">
              <div class="bgimg"><img src="@/assets/img/shop_item.png" alt=""></div>
              <div class="context">
                <div class="title">
                  <div class="titcont">
                    <div class="text">{{ item.title }}</div>
                  </div>
                </div>
                <div class="shopitem">
                  <div class="bg"><img src="@/assets/img/shop_item_bg.png" alt=""></div>
                  <div class="itemcont">
                    <div class="picbg"><img src="@/assets/img/shop_itmbg.png" alt=""></div>
                    <div class="imgcont"><img :src="item.cover_img" alt=""></div>
                  </div>
                </div>
                <div class="price">¥{{ item.actual_price }}<span>¥{{ item.original_price }}</span></div>
              </div>
              <div class="tag" v-if="item.is_hot == 1"><img src="@/assets/img/shop_sale.png" alt=""></div>
            </div>
          </div>
        </van-list>
      </div>
    </div>

  </div>
</template>

<script setup>
import { onMounted, ref } from "vue"
import API from "@/axios";
import { Toast } from "vant";
import { useRouter, useRoute } from "vue-router";
import wx from 'jweixin-js';

// const router = useRouter();
const router = useRouter();
const route = useRoute();

const tabindex = ref(0)
// const scrollindex = ref(0)

const share_id = ref("")
const cate_id = ref(0)
const limit = ref(10)
const page = ref(1)
const loading = ref(false);
const finished = ref(false);
const last_page = ref()

const userToken = ref("")
const shareData = ref()
const code = ref("")

const cate_list = ref([])
const goods_list = ref([])

const scope_url = ref('');//微信公众号授权发起地址

// 需要使用的JS接口列表
const jsApiListArr = [
  "updateTimelineShareData",
  "updateAppMessageShareData",
  'checkJsApi',
];

const reload = ref(false)

const barscroll = ref(null)

const goodsCate = () => {
  // 提交按钮
  API({
    url: "api/goodsCate",
    method: "post",
    data: {
      share_id: share_id.value,
      cate_id: cate_id.value,
      limit: limit.value,
      page: page.value,
    },
  }).then((res) => {
    if (res.data.status == 200) {
      console.log(res.data.data.goods_list)

      shareData.value = res.data.data.share_info
      cate_list.value = res.data.data.cate_list
      // goods_list.value = res.data.data.goods_list
      last_page.value = res.data.data.pages.last_page

      let newArr = [];
      goods_list.value = goods_list.value.concat(res.data.data.goods_list);
      goods_list.value.forEach((el) => {
        const result = newArr.findIndex((ol) => {
          return el.goods_id === ol.goods_id;
        });
        if (result !== -1) {
          newArr[result] = el;
        } else {
          newArr.push(el);
        }
      });

      goods_list.value = newArr;

      page.value = page.value + 1

      if (res.data.data.goods_list.length === 0) {
        finished.value = true;
      } else {
        finished.value = false;
      }

      loadWxShare()

    } else {
      Toast.fail(res.data.msg);
    }
  });
}

const tabclick = (item, index) => {
  tabindex.value = index
  cate_id.value = item.val

  if (index <= 2) {
    barscroll.value.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  } else if (index > 2) {
    barscroll.value.scroll({
      top: 0,
      left: 228 / 2 * (index - 1),
      behavior: "smooth",
    })
  }

  if (index == cate_list.value.length - 1) {
    barscroll.value.scroll({
      top: 0,
      left: 228 / 2 * (cate_list.value.length - 3),
      behavior: "smooth",
    })
  }

  goods_list.value = []
  page.value = 1

  goodsCate()
}

const onLoad = () => {
  if (last_page.value >= page.value) {
    goodsCate()
  }

  finished.value = true;
  loading.value = false;
};

//获取微信配置并初始化微信jssdk
const getWxConfig = (getCode = false) => {
  API({
    url: "/web/wxJs",
    method: "post",
    data: {
      url: encodeURIComponent(window.location.href.split("#")[0]),
    },
  }).then((res) => {
    reload.value = true
    if (res.data.status == 200) {
      scope_url.value = res.data.data.scope_url;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: res.data.data.appId, // 必填，公众号的唯一标识
        timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.data.signature,// 必填，签名
        jsApiList: jsApiListArr
      });

      wx.checkJsApi({
        jsApiList: jsApiListArr,
        success: function () {
          // console.log('wx.checkJsApi:success',res)
          // 以键值对的形式返回，可用的api值true，不可用为false
          // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
        },
        error: function (res) {
          console.log('wx.checkJsApi:error', res)
        }
      });

      wx.error(function (res) {
        console.log('wx.error', res)

        // Toast.fail({
        //   message: '微信JSSDK信息验证失败！'
        // });
          if (reload.value && share_id.value !== "") {
            location.reload();
            reload.value = false
          }
      });

      //是否需要授权登录
      if (getCode) {
        getwxCode();
      }
    } else {
      Toast.fail({
        message: res.data.msg
      });
    }
  });
}

//微信授权
const getwxCode = () => {
  let userToken = window.localStorage.getItem('userToken');
  let hrefUrl = window.location.href.split("#")[0];
  if (!share_id.value) {
    hrefUrl += '?share_id=0';
  }

  if (!userToken) {
    window.location.href = scope_url.value + '?back=' + encodeURIComponent(hrefUrl);
  }
}

//微信登录-》活动详情
const sendCode = () => {
  API({
    url: "/web/visitorLogin",
    method: "post",
    data: {
      code: code.value
    },
  }).then((res) => {
    if (res.data.status == 200) {
      // console.log(res)
      userToken.value = res.data.data.userToken
      window.localStorage.setItem('userToken', userToken.value);
    } else {
      Toast.fail({
        message: res.data.msg
      });
    }
  });
}

//加载分享组件
const loadWxShare = () => {
  wx.ready(function () {
    let shareTitle = shareData.value.share_title;
    let shareDesc = shareData.value.share_desc;
    let shareLink = shareData.value.share_url;
    let shareImg = shareData.value.share_img;
    //1.4以上用这个分享给朋友
    wx.updateAppMessageShareData({
      title: shareTitle,
      desc: shareDesc,
      link: shareLink,
      imgUrl: shareImg,
      success: function () {
        // 分享成功可以做相应的数据处理
      },
      fail: function (res) {
        console.log('updateAppMessageShareData.fail', res)
      }
    })

    //1.4以上用这个分享到朋友圈
    wx.updateTimelineShareData({
      title: shareTitle,
      link: shareLink,
      imgUrl: shareImg,
      success: function () {
        // 分享成功可以做相应的数据处理
      },
      fail: function (res) {
        console.log('updateTimelineShareData.fail', res)
      }
    })
  });
}

const todetail = (item) => {
  router.push({
    name: "shopdetail",
    query: {
      share_id: item.share_id,
      goods_id: item.goods_id
    }
  });
}

onMounted(() => {
  if (route.query.share_id) {
    share_id.value = route.query.share_id
  } else {
    share_id.value = ""
  }

  goodsCate()

  userToken.value = window.localStorage.getItem('userToken')

  if (route.query.code) {
    code.value = route.query.code
  } else {
    // code.value = "081m8EFa1VCVbH0mMvHa16rRjX3m8EFt"
  }

  if (userToken.value) {
    getWxConfig();
  } else {

    if (code.value) {
      //授权回调后,登录并获取活动详情
      getWxConfig();
      sendCode()
    } else {
      //无token无code则开始授权
      getWxConfig(true);
    }
  }
})
</script>

<style lang="less" scoped>
.shop {
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(180deg, #A4C1F9 0%, #AEF9E5 37%, #AEF9E5 49%, #A3BCFF 100%);
  display: flex;
  flex-flow: column;
}

.topimg {
  width: 100%;

  img {
    width: 100%;
    display: block;
  }
}

.contant {
  flex-grow: 1;
  height: 10px;
  overflow-y: auto;
  padding-top: 22px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;

  .tabbar {
    width: calc(100% - 64px);
    box-sizing: border-box;
    margin: 0 32px;
    margin-bottom: 40px;
    overflow: hidden;
    position: relative;
    height: 72px;
    overflow-x: auto;

    .warp {
      display: flex;
      position: absolute;
      transition: all 0.6s;
    }

    .item {
      width: 228px;
      height: 72px;
      position: relative;
      margin-left: -8px;

      .bgimg {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      .text {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 32px;
        color: #868686;
        line-height: 32px;
      }

      .on {
        color: #000000
      }
    }

    .item:first-of-type {
      margin-left: 0;
    }
  }

  .tabbar::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .tabbar::-webkit-scrollbar-thumb {
    width: 0;
    height: 0;
  }

  .tabbar::-webkit-scrollbar-track {
    width: 0;
    height: 0;
  }


  .shoplist {
    flex-grow: 1;
    height: 10px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    overflow-y: auto;

    .listload {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      align-content: flex-start;
    }

    .item {
      width: calc(50% - 40px);
      margin: 0 20px;
      position: relative;
      margin-bottom: 32px;

      .bgimg {
        width: 100%;

        img {
          width: 100%;
          display: block;
        }
      }

      .context {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        .title {
          width: 100%;
          overflow: hidden;
          margin-bottom: 12px;

          .titcont {
            width: 240px;
            height: 44px;
            background: #343B43;
            border-radius: 0px 8px 8px 0px;
            font-size: 24px;
            color: #FFFFFF;
            line-height: 44px;
            transform-origin: left;
            transform: skew(-8deg, 0deg);
            -ms-transform: skew(-8deg, 0deg);
            -moz-transform: skew(-8deg, 0deg);
            -webkit-transform: skew(-8deg, 0deg);
            -o-transform: skew(-9deg, 0deg);
            margin-left: -20px;
            padding-left: 40px;
            box-sizing: border-box;

            .text {
              font-size: 24px;
              color: #FFFFFF;
              line-height: 44px;
              transform-origin: left;
              transform: skew(8deg, 0deg);
              -ms-transform: skew(8deg, 0deg);
              -moz-transform: skew(8deg, 0deg);
              -webkit-transform: skew(8deg, 0deg);
              -o-transform: skew(9deg, 0deg);
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              width: 100%;
            }
          }
        }

        .shopitem {
          width: 100%;
          box-sizing: border-box;
          padding-right: 1.6%;
          margin-bottom: 8px;
          position: relative;

          .bg {
            width: 100%;

            img {
              width: 100%;
              display: block;
            }
          }

          .itemcont {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 50%;
            top: 50%;
            width: 74%;
            -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);

            .picbg {
              width: 100%;

              img {
                width: 100%;
                display: block;
              }
            }

            .imgcont {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              border-radius: 50%;
              overflow: hidden;

              img {
                background: #666;
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
              }
            }
          }
        }

        .price {
          width: 97%;
          font-family: 'DIN-Black';
          font-weight: 900;
          font-size: 40px;
          color: #7DFDE9;
          line-height: 40px;
          padding: 12px 0;
          background: #2E353E;
          display: flex;
          align-items: self-end;
          border-radius: 0 0 20px 20px;
          justify-content: center;

          span {
            font-family: 'DIN-Bold';
            font-weight: bold;
            font-size: 24px;
            color: #868C92;
            line-height: 24px;
            padding-left: 10px;
            text-decoration: line-through;
          }
        }

      }

      .tag {
        position: absolute;
        left: 50%;
        top: 0;
        width: 58%;

        img {
          width: 100%;
          display: block;
        }
      }
    }
  }
}
</style>